import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import USP from "../components/home/usp";

import insuranceHero from "../images/insurance/insurance-hero.png";

function InsurancePage() {
  return (
    <Layout>
      <SEO
        keywords={[`Nubee`, `driving school`, `learners`, `artificial intelligence`]}
        title="Learners"
      />

      <section className="md:p-0 text-left mt-28 md:mt-14">
        <div className="flex flex-col gap-5 md:flex-row px-6 md:max-w-7xl mx-auto items-center">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
                Insurance made for the young driver
              </h1>
            </div>
            <div>
              <p className="py-8 text-black font-body">Car insurance is expensive and complicated, especially for a first time driver. {"That's"} why {"we're"} here to help you find the right coverage to suit your specific situation and wallet.</p>
              <p className="">Contact us for more information and we will get back to you ASAP.</p>
              <div className="flex flex-col gap-5 md:w-11/12 pt-8">
                <USP href="mailto:support@nubee.co.za" title="Get my tailor-made price"></USP>
              </div>
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={insuranceHero}
            className="object-contain w-3/4 md:w-3/6 mx-auto"
          />
        </div>
      </section>
    </Layout>
  );
}

export default InsurancePage;