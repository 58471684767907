import PropTypes from "prop-types";
import * as React from 'react'
// import { Link } from 'gatsby'

import rightArrow from "/src/icons/black-chevron.svg";

const USP = ({ title, description, href }) => {
  return (
    <div>
      {/* <div className="flex items-center justify-between bg-white p-3 text-black font-body text-sm rounded-lg border-2 border-black shadow-md shadow-gray-400">
        <span className="pl-2 text-black text-lg font-bold font-heading">{title}</span><span className="hidden md:inline-block">{description}</span>
        <span className="text-black">Arrow</span>
        {children}
      </div> */}
      <a href={href}>
        <button className="w-11/12 flex items-center justify-between font-body text-sm gap-5 bg-nubee-pink hover:bg-white text-black font-bold py-2 px-4 border-2 border-l-4 border-b-4 border-black hover:border-black rounded-lg shadow-gray-500 shadow">
          <span className="pl-2 text-black text-lg font-bold font-heading">{title}</span><span className="hidden md:inline-block">{description}</span>
          <span className="text-black"><img src={rightArrow}></img></span>
        </button>
      </a>
    </div>
  )
}

USP.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  href: PropTypes.node.isRequired,
};

export default USP